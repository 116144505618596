import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from './HomePage';
import Login from './Login';

const App = () => {
    return (
        <Routes>
            <Route path="/" element={localStorage.getItem('username') ? <Home/> : <Login/>}/>
            <Route path="/home" element={<Home/>}/>
            <Route path="/login" element={<Login/>}/>
        </Routes>
    );
};

export default App;
