import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import constants from "./constants"
const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = (e) => {
        try {
            e.preventDefault();
            // Handle login logic here
            let findKeyUser = Object.keys(constants.user).filter(k=>constants.user[k]['username']===username)
            if(findKeyUser){
                let password = constants.user[findKeyUser]['password']
                if(password===password){
                    localStorage.setItem('username',username)
                    window.location.href = '/home'
                }else {
                    alert('username หรือ password ไม่ถูกต้อง')
                }
            }
            // console.log(constants.user[findKeyUser])
        }catch (e){
            console.log(e)
        }


    };

    return (
        <div className="container">
            <div className="row justify-content-center mt-5">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header text-center">
                            Login
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleLogin}>
                                <div className="form-group">
                                    <label htmlFor="username">Username</label>
                                    <input type="text" className="form-control" id="username" value={username} onChange={(e) => setUsername(e.target.value)} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">Password</label>
                                    <input type="password" className="form-control" id="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                </div>
                                <br/>
                                <button type="submit" className="btn btn-primary w-100">Login</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
