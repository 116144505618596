import "./DisplayImage.css";
import { useMemo } from "react";

const DisplayImage = ({
  elementRef,
  numberCooponInput,
  dateCooponInput,
  noCooponInput,
  selectedFile,
  sizeImgMin,
  sizeImgMax,
  preview,
  arrNum,
  colorFont,
  colorNumber
}) => {
  const dateTH = useMemo(() => {
    if (!dateCooponInput) {
      const date = new Date();

      const result = date.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      return result;
    }

    const date = new Date(dateCooponInput);

    const result = date.toLocaleDateString("th-TH", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    return result;
  }, [dateCooponInput]);

  const dateEN = useMemo(() => {
    if (!dateCooponInput) {
      const date = new Date();

      const result = date.toLocaleDateString("en-GB", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      return result;
    }
    const date = new Date(dateCooponInput);

    const result = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    return result;
  }, [dateCooponInput]);

  const convertNumberTxt = (str) => {
    //console.log(typeof str);
    const listNumber = [
      "ZER",
      "ONE",
      "TWO",
      "THR",
      "FOR",
      "FIV",
      "SIX",
      "SEV",
      "EGT",
      "NIN",
    ];
    const splitStr = str.split("");
    const numberToText = splitStr.map(
      (txtNumber) => listNumber[Number(txtNumber)]
    );
    return (
      <div className="disPlaytextNumberloop">
        {numberToText.map((m, i) => (
          <div className="disPlaytxtNumCoopon" >
            <div className="disPlaytxtNum1" style={{color:colorNumber}}>{splitStr[i]}</div>
            <div className="disPlaytxtNum2" style={{color:colorFont}}>{m}</div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <div
        className="cardImage"
        style={{
          backgroundImage: `url(${preview})`,
        }}
        ref={elementRef}
      >
        <div className="cardContent">
          {/* {selectedFile && <img src={preview} />} */}
          <div className="contentNumberCoopon "  style={{marginLeft:'-14px'}}>
            <div>{convertNumberTxt(numberCooponInput)}</div>
          </div>
          {/*<div className="contentDateTHCoopon">*/}
          {/*  <div>{dateTH}</div>*/}
          {/*</div>*/}
          {/*<div className="contentDateENCoopon">*/}
          {/*  <div className="textBoxStyles textBoxDateENCoopon">{dateEN}</div>*/}
          {/*</div>*/}
          {/*<div className="contentNoCoopon">*/}
          {/*  <div className="textBoxStyles textBoxNoCoopon">{noCooponInput}</div>*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
};

export default DisplayImage;
