import { useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./FormInput.css";
import Row from "react-bootstrap/Row";
import { NumericFormat } from "react-number-format";

const FormInput = ({
  setNumberCooponMin,
  setNumberCooponMax,
  setNoMinCooponInput,
  setNoMaxCooponInput,
  setDateCooponInput,
  setSizeImgMin,
  setSizeImgMax,
  onSelectFile,
  arrNum,
  setArrNum,
  setColorNumber,
  setColorFont,
}) => {
  const [validated, setValidated] = useState(false);

  // create a preview as a side effect, whenever selected file is changed
  const logout = () => {
    localStorage.clear()
    window.location.href = '/login'
  }
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  const handleSetnumber = (number) => {
    setNumberCooponMax(number);

    handleSetNum(number);
  };

  const handleSetNum = (numberCooponInput) => {
    const n = numberCooponInput.toString();
    let e;
    for (var i = 0; i < numberCooponInput.length; i++) {
      switch (n.substr(i)) {
        case "9":
          e = "NIN";
          break;
        case "8":
          e = "EGT";
          break;
        case "7":
          e = "SEV";
          break;
        case "6":
          e = "SIX";
          break;
        case "5":
          e = "FIV";
          break;
        case "4":
          e = "FOU";
          break;
        case "3":
          e = "THR";
          break;
        case "2":
          e = "TWO";
          break;
        case "1":
          e = "ONE";
          break;
        case "0":
          e = "ZER";
          break;
        default:
          e = "";
          break;
      }
    }
    if (numberCooponInput.length > arrNum.length) {
      setArrNum(
        // Replace the state
        [
          // with a new array
          ...arrNum, // that contains all the old items
          { e }, // and one new item at the end
        ]
      );
    } else {
      arrNum.pop();
    }
  };

  return (
    <Form validated={validated} onSubmit={handleSubmit}>

      <Row className="textleft mb-1">
        <Col md="6">หมายเลขตั๋ว</Col>
        <Col md="3">จำนวนตั๋วชุด</Col>
        <Col md="3">งวด</Col>
      </Row>
      <Row className="mb-3">
        <Col md="3">
          <Form.Control
            className="inputStyles"
            type={'text'}
            onChange={(e) => {
              setNumberCooponMin(e.target.value);
            }}
            maxLength={6}
          />
        </Col>
        <Col md="3">
          <Form.Control
              type={'text'}
            className="inputStyles"
            onChange={(e) => {
              handleSetnumber(e.target.value);
            }}
            maxLength={6}
          />
        </Col>
        <Col md="3" style={{ display: "none" }}>
          <NumericFormat
            disabled
            className="inputStyles"
            onChange={(e) => {
              setNoMinCooponInput(e.target.value);
            }}
            defaultValue="1"
            maxLength={3}
          />
        </Col>
        <Col md="3">
          <NumericFormat
            className="inputStyles"
            onChange={(e) => {
              setNoMaxCooponInput(e.target.value);
            }}
            maxLength={3}
          />
        </Col>
        <Col md="3">
          <Form.Control
            placeholder="งวด"
            onChange={(e) => {
              setSizeImgMin(e.target.value);
            }}
            maxLength={2}
          />
        </Col>
      </Row>
      <Row className="textleft mb-1">
        <Col md="6">วันที่ขาย</Col>
        <Col md="6">รูปbackground</Col>
      </Row>
      <Row className="mb-3">
        <Col md="6">
          <Form.Control
            type="date"
            onChange={(e) => {
              setDateCooponInput(e.target.value);
            }}
          />
        </Col>
        <Col md="6">
          <Form.Control type="file" onChange={onSelectFile} />
        </Col>
      </Row>

      <Row className="textleft mb-1">
        <Col md="3">สี (ตัวเลข)</Col>
        <Col md="3">สี (ตัวหนังสือ)</Col>
      </Row>
      <Row className="mb-3">
        <Col md="3">
          <Form.Control
            placeholder="#343a40"
            onChange={(e) => {
              setColorNumber(e.target.value);
            }}
          />
        </Col>
        <Col md="3">
          <Form.Control
            placeholder="#343a40"
            onChange={(e) => {
              setColorFont(e.target.value);
            }}
          />
        </Col>
      </Row>

      <Row className="mb-12">
        <Col  md="12">
          <p><a href="#" className="text-secondary" onClick={logout}>ออกจากระบบ</a></p>

        </Col>
      </Row>
    </Form>
  );
};

export default FormInput;
