
const json = {
   user : [
     // {
     //   'username' : 'adminsalak',
     //   'password' : 'oqOdlSYrvAL7NsP'
     // },
     {
       'username' : 'admin@salakthailottery',
       'password' : 'JYy@bhHyFJYtC%3x'
     },
  ]

}
// @ts-ignore
export default json;

